<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
  >
    <b-tabs class="shadow p-2">
      <div class="d-flex  align-items-center justify-content-between">
        <div class="d-flex align-items-baseline ">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$router.back()"
          >
            <feather-icon
              class="mr-25"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <h3 class="ml-1">
            All Forms
            <b-badge
              variant="primary"
              class="ml-50"
            >
              {{ $route.params.mname }}
            </b-badge>
          </h3>
        </div>
        <div>
          <button
            :class="`btn btn-cart rounded ${view ? 'btn-primary': 'btn-outline-primary'}`"
            type="button"
            @click="updateView(true)"
          >
            Grid
          </button>
          <button
            :class="`btn btn-cart rounded ml-1 ${!view ? 'btn-primary': 'btn-outline-primary'}`"
            type="button"
            @click="updateView(false)"
          >
            List
          </button>
        </div>
      </div>
      <hr>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search response"
            type="text"
          />
        </b-form-group>
      </div>
      <!-- card -->
      <section
        v-if="view"
        class="grid-view mt-1"
      >
        <b-row v-if="rows.length===0">
          <b-col>
            <b-card
              tag="article"
              style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              class="ecommerce-card"
            >
              <h6 class="d-flex align-items-center justify-content-center  mb-0 text-center">
                No resources are available
              </h6>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col
            md="4"
            style="margin-bottom: 10px"
          >
            <b-card
              v-for="(program, i) in programs"
              :key="i"
              :title="program.title"
              class="ecommerce-card"
              img-alt="card img"
              img-top
              no-body
            >
              <b-card-body>
                <b-card-text>
                  <b-card-title class="mb-1">
                    {{ program.title }}
                    <b-badge
                      variant="primary"
                      size="lg"
                      class="mr-25"
                    >
                      Round {{ program.round_number }}
                    </b-badge>
                    <hr>
                  </b-card-title>
                  <span class="h5 d-block">Application Status: <b-badge
                    pill
                    :variant="program.status === 'enabled' ? 'success' : ''"
                    class="text-capitalize"
                    size="lg"
                    v-text="program.status"
                  /></span>
                  <span class="h5 d-block">
                    Review Status: <b-badge
                      pill
                      :variant="program.review_status === 'enabled' ? 'success' : ''"
                      class="text-capitalize"
                      size="lg"
                      v-text="program.review_status"
                    /></span>
                </b-card-text>
              </b-card-body>
              <!-- Product Actions -->
              <div class="item-options text-center">
                <b-link
                  :to="`mentor-portal/forms/${$route.params.fid}/${$route.params.mname}/responses/${$route.params.rid}/evaluation`"
                  class="btn btn-primary btn-cart"
                >
                  <span>Review</span>
                </b-link>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </section>
      <!-- table -->
      <vue-good-table
        v-if="!view"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'actions'">
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50"
            >View Details</b-button>
          </span>
          <span v-if="props.column.field === 'actions'">
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50"
              :to="{ name: 'mentors-forms-response-view', params: { fid: props.row.id, mname: props.row.title, rid: props.row.rid } }"
            >View Responses</b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50"
              :to="{ name: 'mentor-forms-preview', params: { fid: props.row.id, mname: props.row.title } }"
            ><feather-icon icon="EyeIcon" /></b-button>
            <b-button
              v-if="!props.row.is_default"
              v-b-modal.CopyModal
              size="sm"
              variant="outline-primary"
              class="mr-50"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
          </span>
        </template>
        <template
          v-slot:column-filter="{ column, updateFilters }"
        >
          <span v-if="column.label === 'Operation'">
            <treeselect
              style="border-collapse: separate"
              :normalizer="filterNormalizer"
              :append-to-body="true"
              :options="operations"
              placeholder="Select Phase, Subphase or Activities"
              class="form-control p-0 border-0"
              @input="(val) => updateFilters(column, val)"
            />
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','20','30']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-modal
        id="knowledgeBaseModal"
        ok-only
        no-close-on-backdrop
        :ok-title="`${update ? 'Update' : 'Upload'}`"
        size="md"
        :title="`${update ? 'Update' : 'Add'} Resource`"
        @ok="addFile"
      >
        <b-overlay :show="$apollo.loading || mutationLoading">
          <validation-observer
            ref="uploadForm"
            tag="form"
          >
            <b-row>
              <b-col cols="12">

                <b-form-group
                  label="Title"
                  label-size="sm"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <b-form-input
                      v-model="title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Title"
                      required
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Description"
                  label-size="sm"
                >
                  <b-form-textarea
                    v-model="description"
                    placeholder="File Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Recommendation"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                    label="Recommendation Level"
                    label-size="sm"
                  >
                    <v-select
                      v-model="recommendationLevel"
                      :options="['Priority', 'Interesting', 'Additional', 'Good-Read']"
                      placeholder="Recommendation Level"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <!-- primary -->
                <div
                  class="d-flex align-items-center"
                  style="height:100%"
                >
                  <span>Is Private?</span>
                  <b-form-checkbox
                    v-model="is_private"
                    class="ml-50"
                    inline
                    switch
                  ><span class="switch-icon-left">
                     <feather-icon icon="LockIcon" />
                   </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="UsersIcon" />
                    </span></b-form-checkbox>
                </div>
              </b-col>
              <b-col cols="12">
                <!-- primary -->
                <div class="d-flex align-items-center">
                  <span>Upload File?</span>
                  <b-form-checkbox
                    v-model="is_internal"
                    class="ml-50"
                    inline
                    switch
                  ><span class="switch-icon-left">
                     <feather-icon icon="UploadIcon" />
                   </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="LinkIcon" />
                    </span></b-form-checkbox>
                </div>
                <hr>
              </b-col>
              <b-col
                v-if="is_internal"
                cols="12"
              >
                <h6>Upload File</h6>
                <!-- Styled -->
                <validation-provider
                  #default="{ errors }"
                  name="File"
                  rules="size:10000"
                >
                  <b-form-file
                    v-model="file"
                    :state="errors.length > 0 ? false:null"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col
                v-else
                cols="12"
              >
                <h6>External Link</h6>
                <b-form-input
                  v-model="file_link"
                  placeholder="https://"
                />
              </b-col>
            </b-row>
          </validation-observer>
        </b-overlay>
      </b-modal>
      <b-modal
        id="CopyModal"
        title="Copy Link"
        ok-title="Copy"
      >
        <b-card-title>form title</b-card-title>
        <b-input-group>
          <b-form-input
            :id="`application-link`"
            size="sm"
            :value="`hello world`"
            @keydown="event => event.preventDefault()"
            @focus="event => {event.target.select()}"
          />
          <template #append>
            <!-- Copy to Clipboard button with Copy icon at start -->
            <b-button
              size="sm"
              variant="outline-primary"
              @click="copyToClipboard('hello world')"
            >
              <feather-icon icon="CopyIcon" />
              Copy
            </b-button>
          </template>
        </b-input-group>
      </b-modal>
    </b-tabs>
  </b-overlay>
</template>
<script>

import {
  BBadge,
  BCard,
  BInputGroup,
  BButton,
  BCol,
  BFormCheckbox,
  BFormFile,
  BTabs,
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
  BCardTitle,
  BFormSelect,
  // BCardFooter,
  // BLink,
  BCardText,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { Promised } from 'vue-promised'
import { getUserData } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, size } from '@core/utils/validations/validations'

export default {
  components: {
    BRow,
    BCard,
    BInputGroup,
    BCol,
    BTabs,
    BModal,
    BFormInput,
    BFormGroup,
    BCardTitle,
    BButton,
    VueGoodTable,
    BPagination,
    // BCardFooter,
    // BLink,
    BCardText,
    BFormSelect,
    BFormTextarea,
    vSelect,
    Treeselect,
    BBadge,
    BFormCheckbox,
    BFormFile,
    // Promised,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      view: true,
      mutationLoading: false,
      update: false,
      searchTerm: '',
      selectedRow: 0,
      pageLength: 10,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Recommendation',
          field: 'recommendation',
        },
        {
          label: 'Verdict',
          field: 'verdict',
        },
        {
          label: 'Actions',
          field: 'actions',
        },
      ],
      rows: [],
      is_internal: true,
      is_private: true,
      file_link: null,
      file: null,
      recommendationLevel: null,
      title: null,
      description: '',
      selectedOperation: null,
      required,
      size,
    }
  },
  computed: {
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      if (!arr.length) return []
      const newarr = arr
        .filter(obj => obj.role === 'superadmin')
        .filter(obj => obj) // Remove nulls
      return newarr[0]
    },
  },
  methods: {
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.$bvToast.toast('Application link copied to clipboard', {
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
          })
        }).catch(() => {
        })
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = text
        textarea.style.position = 'fixed' // ensure the textarea is rendered offscreen
        document.body.appendChild(textarea)
        textarea.focus()
        textarea.select()
        try {
          document.execCommand('copy') // execute the copy command
        } catch (err) {
          // failed to copy
        } finally {
          document.body.removeChild(textarea) // remove the textarea from the DOM
        }
      }
    },
    updateView(view) {
      this.view = view
    },

    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    filterNormalizer(node) {
      return {
        id: node.title,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    getLink(key) {
      return fetch(`https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/?file_key=${key}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => data.url)
    },
    async getUserOrgId() {
      const userData = await localStorage.getItem('userData')
      return userData.associatedOrgDetails.map(org => {
        if (org.role === 'superadmin') {
          return org.organization_id
        }
        return 0
      })
    },
    async addFile(bvModalEvent) {
      bvModalEvent.preventDefault()
      const validation = await this.$refs.uploadForm.validate()
      if (!validation) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please fill all required fields',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.file && !this.file_link && !this.update) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please upload a file or provide a link',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        })
        return false
      }
      this.mutationLoading = true
      let fileLink = this.file_link
      if (this.is_internal && this.file) {
        const formData = new FormData()
        formData.append('file', this.file)
        await fetch('https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/upload/', {
          method: 'POST',
          body: formData,
        }).then(response => response.json())
          .then(data => {
            fileLink = data.file_key
            return data
          })
      }
      await this.$apollo.mutate(
        {
          mutation: gql`mutation MyMutation {
                    ${this.update ? `
                      update_programs_knowledgeresourcetable_by_pk(pk_columns: {id: ${this.selectedRow} }, _set: {document_name: "${this.title}",recommendation: "${this.recommendationLevel}", description: "${this.description}" ${this.file ? `, file: "${fileLink}"` : ''}, is_internal: "${this.is_internal}", ${getUserData().associatedOrgDetails.length > 0 ? `partner_id: "${getUserData().associatedOrgDetails[0].users_organizationtable.id}"` : `user_id: "${getUserData().id}"`}, is_private: "${this.is_private}", knowledge_resource_id: ${this.$route.params.lid}}) {
                        id
                      }
                    ` : `
                      insert_programs_knowledgeresourcetable_one(object: {document_name: "${this.title}",recommendation: "${this.recommendationLevel}", description: "${this.description}", file: "${fileLink}", is_internal: "${this.is_internal}", is_private: "${this.is_private}", ${getUserData().associatedOrgDetails.length > 0 ? `partner_id: "${getUserData().associatedOrgDetails[0].users_organizationtable.id}"` : `user_id: "${getUserData().id}"`}, knowledge_resource_id: ${this.$route.params.lid}}) {
                        id
                      }
                    `}
                    }`,
          update: () => {
            this.mutationLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Resource ${this.update ? 'update' : 'added'} successfully`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('knowledgeBaseModal')
            this.$apollo.queries.rows.refetch()
          },
        },
      ).catch(() => {
        this.mutationLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        })
      })
      this.title = null
      this.file_link = null
      this.description = null
      this.selectedOperation = null
      this.recommendationLevel = null
      this.selectedOperation = null
      return true
    },
    deleteFile(id) {
      // msgOkcofirm
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this file?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete File',
        },
      )
        .then(value => {
          if (value === false) {
            this.mutationLoading = true
            this.$apollo.mutate(
              {
                mutation: gql`mutation MyMutation {
                      update_programs_knowledgeresourcetable_by_pk(pk_columns: {id: ${id}}, _set: {is_deleted: true}) {
                        id
                      }
                    }`,
                update: () => {
                  this.mutationLoading = false
                  this.$apollo.queries.rows.refetch()
                },
              },
            )
          }
        })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_knowledgeresourcetable(order_by: { id: desc}, where: {knowledge_resource_id: {_eq:  ${this.$route.params.lid}}, _or: [{is_default: {_eq: true}}, {${getUserData().associatedOrgDetails.length > 0 ? `partner_id: {_eq: ${getUserData().associatedOrgDetails[0].users_organizationtable.id}}` : `user_id: {_eq: ${getUserData().id}}`}}]}) {
            id
            description
            document_name
            file
            recommendation
            is_internal
            is_default
            is_private
          }
        }`
      },
      update: data => data.programs_knowledgeresourcetable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
